import React, {useEffect} from 'react';
import Error404 from "./views/Error404";
import {Route, Routes} from 'react-router-dom';
import {Home} from "./views/Home";
import SingleProduct from "./views/products/SingleProduct";
import {useStoreState} from "pullstate";
import {AppCall, getCallDetails} from "./stores";

function App() {

    const details = useStoreState(AppCall, getCallDetails);

    useEffect(() => {
        console.log("Transcripts: ", details.transcripts.length)
        console.log('Started the app');
    }, [details.transcripts]);

    return (
        <Routes>
            <Route index={true} element={<Home/>}/>

            <Route path="/:category/:slug" element={<SingleProduct/>}/>

            <Route path="*" element={<Error404/>}/>
        </Routes>
    );
}

export default App;
