import {ActionButton} from "./ActionButton";
import React from "react";
import {useStoreState} from "pullstate";
import {AppCall, getVapi, updateBuy, updateShowAddToCart} from "../../stores";
import toast, {Toaster} from "react-hot-toast";

interface Props extends React.ComponentProps<typeof ActionButton> {
    children?: any;
}

export const AddToCart: React.FC<Props> = ({children, ...props}) => {

    const vapi = useStoreState(AppCall, getVapi);

    const handleClick = () => {
        vapi.send({
            type: "add-message",
            message: {
                role: "system",
                content:
                    "L'utilisateur a appuyé sur le bouton « Ajouter au panier ». Informez-le que vous avez remarqué qu'il a ajouté le produit à son panier et passez aux étapes suivantes de la conversation.",
            },
        });
        return new Promise((resolve) => {
            const out = setTimeout(() => {
                clearTimeout(out);
                updateShowAddToCart(false);
                updateBuy(true);
                return resolve(true);
            }, 2000);
        });
    }

    return (
        <>
            <ActionButton {...props} onClick={() => toast.promise(handleClick(), {
                loading: "Adding to cart...",
                success: <b>Item added to Cart!</b>,
                error: <b>Could not add to cart.</b>,
            })}>
                {children ? children : "Ajouter Au Panier"}
            </ActionButton>
            <Toaster/>
        </>
    );
};
