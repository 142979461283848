import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Product} from "../../interfaces";
import {ActionButton, AddToCart} from "../../components/buttons";
import Error404 from "../Error404";
import {useStoreState} from "pullstate";
import {AppCall, getProduct, getProducts, replaceProduct} from "../../stores";
import TalkToUs from "./call/TalkToUs";

const SingleProduct: React.FC = () => {
    const {slug} = useParams();

    const product = useStoreState(AppCall, getProduct);
    const products = useStoreState(AppCall, getProducts);
    const navigate = useNavigate();

    const goToProduct = (item: Product) => {
        navigate(`/${item.category}/${item.slug}`);
    };

    useEffect(() => {
        if (slug && slug !== product.slug) {
            const current = products.find((p) => p.slug === slug) || products[0];
            replaceProduct(current);
        }
    }, [slug, product, products]);

    return product ? (
        <div className="w-full max-w-5xl mx-auto p-4">
            <div className="grid grid-cols-12 gap-4 m-4">
                <div className="col-span-full md:col-span-6">
                    <div className="w-full">
                        <img
                            className="bg-gray-200 bg-opacity-70 w-full h-auto object-cover"
                            src={product.image}
                            alt={product.name}
                        />
                    </div>
                    <div className="col-span-full grid grid-cols-12 gap-4 mt-4">
                        {products.map((p) => (
                            <div className="col-span-full md:col-span-6" key={p.id}>
                                <ActionButton
                                    variant="outlined"
                                    color={p.id === product.id ? "secondary" : "primary"}
                                    onClick={() => goToProduct(p)}
                                    disabled={p.id === product.id}
                                >
                                    <span className="text-sm truncate">{p.ref}</span>
                                </ActionButton>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-span-full md:col-span-6 px-4 grid grid-cols-12 gap-4">
                    <div className="col-span-full text-gray-500">
                        <h1 className="text-3xl font-bold">{product.shortName}</h1>
                        <h4 className="text-sm">Réf. {product.ref}</h4>
                    </div>
                    <div className="col-span-full text-3xl font-semibold">
                        {product.price}{" "}
                        <span className="text-xs font-normal"> Prices incl. VAT </span>
                    </div>

                    <div className="col-span-full">
                        <h3 className="text-lg font-semibold mb-2 mt-4">Fonctionnement</h3>
                        {product.features.map((feature) => (
                            <span className="block text-sm pb-2" key={feature}>
                ✓ {feature}
              </span>
                        ))}
                    </div>
                    <div className="col-span-full">
                        <AddToCart buttonClass="w-full"></AddToCart>
                    </div>
                    <div className="col-span-full">
                        <TalkToUs buttonClass="w-full" />
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <Error404/>
    );
};

export default SingleProduct;
