import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ActionButton} from "../../../components/buttons";
import {useStoreState} from "pullstate";
import {
    AppCall,
    endCall,
    getCall,
    getCallStates,
    getEndCallRequest,
    getIp,
    getProduct,
    getTranscripts,
    getVapi,
    updateShowAddToCart,
    updateTranscript
} from "../../../stores";
import {SupportIcon, TelOnIcon} from "../../../components/icons";
import Popup from "reactjs-popup";
import {AnimatePresence, motion} from "framer-motion";
import {CallVolumeLevel} from "./CallVolumeLevel";
import {CallButtons} from "./CallButtons";
import {CallTranscript} from "./CallTranscript";
import {CallChat} from "./CallChat";
import {ConversationTranscript} from "../../../interfaces";
// @ts-ignore
import media from "../../../assets/media/ring_phone.wav";
import {ClientRequest} from "../../../assistant";


interface Props extends React.ComponentProps<typeof ActionButton> {
    children?: React.ReactNode;
}


const TalkToUs: React.FC<Props> = ({children, ...props}) => {

    // --- Start Constants
    const tabs = [
        {
            icon: <TelOnIcon className="w-6 h-6"/>,
            label: "Call",
        },
        {
            icon: <SupportIcon className="w-6 h-6"/>,
            label: "Transcript",
        },
    ];
    // --- End Constants

    // --- Start States
    const [open, setOpen] = useState(false);
    const product = useStoreState(AppCall, getProduct);
    const vapi = useStoreState(AppCall, getVapi);
    const states = useStoreState(AppCall, getCallStates);
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const ip = useStoreState(AppCall, getIp);
    const request = useStoreState(AppCall, getEndCallRequest);
    // --- End States

    // --- Start Getters
    const audio = useMemo(() => new Audio(media), []);
    const transcripts = useStoreState(AppCall, getTranscripts);
    const call = useStoreState(AppCall, getCall);
    // --- End Getters

    // -- Start Actions
    const stopRing = useCallback(() => {
        audio.pause();
        audio.currentTime = 0;
    }, [audio]);


    const openCallWindow = () => {
        // onCallStarted && onCallStarted();
    };

    const setIp = useCallback(async () => {
        if (!ip) {
            const res = await ClientRequest.getClientIp();
            AppCall.update((state) => ({...state, ip: res}));
        }
        return ip;
    }, [ip])

    const callEnded = useCallback(async () => {
        // request.transcript = states.
        await endCall(request)
    }, [request])
    // --- End Actions

    // --- Start Watchers
    useEffect(() => {
        setIp();
    }, [setIp]);
    useEffect(() => {
        vapi.on("message", (message: ConversationTranscript) => {
            if (message.type === "transcript") {
                if (message.transcriptType === "final") {
                    updateTranscript(message);
                }
                const text = message.transcript.toLowerCase();
                if ((text.includes('ajouter au panier') || text.includes('add to cart')) && !states.showAddToCart) {
                    updateShowAddToCart(true);
                }
            }
        });

        vapi.on("call-end", async () => {
            await callEnded();
        })

        vapi.on("call-start", () => {
            if (!states.inCall) {
                AppCall.update((state) => ({
                    ...state,
                    inCall: true,
                }));
            }
        })

        vapi.on("volume-level", (value: number) => {
            AppCall.update((state) => ({...state, volume: value}));
        });

        // vapi.on("speech-start", () => {
        //     if (!states.inCall) {
        //         AppCall.update((state) => ({
        //             ...state,
        //             inCall: true,
        //         }));
        //     }
        // });

    }, [call, callEnded, props, request, states.inCall, states.showAddToCart, transcripts, vapi]);

    useEffect(() => {
        if (states.inCall) {
            stopRing();
        }
    }, [states.inCall, stopRing]);
    // --- End Watchers

    return (
        <div>
            <Popup trigger={
                <ActionButton
                    color="secondary"
                    variant="outlined"
                    {...props}
                    onClick={openCallWindow}
                >
                    {children ? children : "Appelez-nous"}
                </ActionButton>
            }
                   modal
                   nested
                   closeOnDocumentClick={false}
                   closeOnEscape={false}
                   onOpen={() => setOpen(true)}
                   onClose={() => setOpen(false)}
                   open={open}
            >
                <div className="m-4 w-screen max-w-lg">
                    <div className="bg-white shadow-lg relative">
                        <span className="right-0 top-0 absolute">
                            <ActionButton
                                color="danger" buttonClass="rounded-none rounded-bl-2xl"
                                onClick={() => setOpen(false)}
                            >
                                X
                            </ActionButton>
                        </span>
                        <AnimatePresence mode="wait">
                            <motion.div
                                className="p-4"
                                key={selectedTab ? selectedTab.label : "empty"}
                                initial={{y: 10, opacity: 0}}
                                animate={{y: 0, opacity: 1}}
                                exit={{y: -10, opacity: 0}}
                                transition={{duration: 0.2}}
                            >
                                <div
                                    className={`col-span-full ${
                                        selectedTab.label === "Transcript" ? "block" : "hidden"
                                    }`}
                                >
                                    <CallTranscript/>

                                    {/*<CallChat/>*/}
                                </div>
                                <div
                                    className={`grid grid-cols-12 gap-4 ${
                                        selectedTab.label === "Call" ? "block" : "hidden"
                                    }`}
                                >
                                    <div className="col-span-12 flex flex-col justify-center items-center">
                                        <SupportIcon className="w-20 h-20 text-gray-500 my-4"/>
                                        <CallVolumeLevel/>
                                    </div>
                                    <div className="col-span-12 text-sm text-center">
                                        Notre assistant répondra à vos questions sur{" "}
                                        <span className="font-semibold">{product.name}</span>
                                    </div>
                                    <div className="col-span-full">
                                        <CallButtons audio={audio}/>
                                    </div>
                                    <div className="col-span-full">
                                        <CallChat/>
                                    </div>
                                </div>
                            </motion.div>
                        </AnimatePresence>
                        <div className="">
                            <ul className="flex justify-between">
                                {tabs.map((item) => (
                                    <li
                                        key={item.label}
                                        className={`w-full h-full text-center cursor-pointer px-4 py-2 flex items-center space-x-2 ${
                                            item.label === selectedTab.label
                                                ? "selected bg-green-50 border-b-2 border-green-500"
                                                : ""
                                        }`}
                                        onClick={() => setSelectedTab(item)}
                                    >
                                        <span>{item.icon}</span> <span>{item.label}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </Popup>
        </div>
    );
};

export default TalkToUs;
