import {useStoreState} from "pullstate";
import React from "react";
import {AppCall, getCallVolume} from "../../../stores";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    //
}

export const CallVolumeLevel: React.FC<Props> = ({...props}) => {
    const numBars = 10;

    const volume = useStoreState(AppCall, getCallVolume);

    return (
        <div {...props}>
            <div className="flex">
                {Array.from({length: numBars}, (_, i) => (
                    <div
                        key={i}
                        className={`w-3 h-3 m-0.5 rounded-[3px] ${
                            i / numBars < volume
                                ? " bg-green-500"
                                : " bg-gray-300 bg-opacity-40"
                        }`}
                    />
                ))}
            </div>
        </div>
    );
};
