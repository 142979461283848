import React, {useState} from "react";
import {useStoreState} from "pullstate";
import {AppCall, getCallStates, getVapi, updateTranscript} from "../../../stores";
import {ConversationTranscript} from "../../../interfaces";
import {ActionButton} from "../../../components/buttons";

interface Props {
    //
}

export const CallChat: React.FC<Props> = ({...props}) => {
    const [message, setMessage] = useState("");

    const vapi = useStoreState(AppCall, getVapi);
    const states = useStoreState(AppCall, getCallStates);

    const submit = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        vapi.send({
            type: "add-message",
            message: {
                role: "user",
                content: message,
            },
        });
        const current: ConversationTranscript = {
            type: "transcript",
            role: "user",
            transcriptType: "final",
            transcript: message,
        };

        updateTranscript(current);
        setMessage("");

        console.log("Sent...");
        //
    };

    //--- Start watchers
    //
    //--- End watchers

    return (
        <form className={`${states.inCall ? "block" : "hidden"}`} onSubmit={submit}>
            {/*<div className={"overflow-auto h-40 w-full"}>*/}
            {/*    {JSON.stringify(call)}*/}
            {/*</div>*/}
            <span className="text-xs mb-2">Utilisez cette zone de saisie pour communiquer avec notre assistant et également pour fournir votre nom et votre e-mail si nécessaire.</span>
            <input
                className="w-full border border-gray-200 rounded-lg focus:border-green-200 active:border-none px-4 py-1 mt-2 placeholder:font-light placeholder:text-xs"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                placeholder="Type your message here..."/>
            <div className="text-right">
                <ActionButton type="submit" buttonClass="rounded-lg mt-4">
                    Submit
                </ActionButton>
            </div>
        </form>
    );
};
