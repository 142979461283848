import { v4 as uuidv4 } from "uuid";

import {ClientRequest} from "./assistant";

export const VAPI_PUBLIC_KEY = process.env.REACT_APP_VAPI_PUBKEY ?? "";
export const VAPI_ASSISTANT_ID = process.env.REACT_APP_VAPI_ASSISTANT_ID ?? "";
export const ASSISTANT_NAME = process.env.REACT_APP_ASSISTANT_NAME ?? "Lily";
export const API_HOST = process.env.REACT_APP_API_HOST ?? "http:localhost:3000";
export const FAQ_ENDPOINT = `${API_HOST}/${process.env.REACT_APP_FAQ_ENDPOINT}`;
export const TRANSCRIPT_ENDPOINT = `${API_HOST}/${process.env.REACT_APP_TRANSCRIPT_ENDPOINT}`;
export const IP_ADDRESS_ENDPOINT = `${API_HOST}/${process.env.REACT_APP_IP_ADDRESS_ENDPOINT}`;
export const identifier = localStorage.getItem("identifier");

export const getLocalItem = (key: string) => {
    return localStorage.getItem(key);
};

export const setLocalItem = (key: string, data: any) => {
    return localStorage.setItem(key, data);
};

export const getIdentifier = async () => {
    let identifier = localStorage.getItem("identifier");
    let response = identifier;
    if (!identifier) {
        identifier = uuidv4();
        const ip = await ClientRequest.getClientIp();
        response = `${ip}:${identifier}`;
        localStorage.setItem("identifier", response);
    }
    return response || "";
};

export const setLastSent = (callId: string) => {
    localStorage.setItem("last_sent", callId);
};

export const getLastSent = () => {
    return localStorage.getItem("last_sent");
};
