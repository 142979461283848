import {motion} from "framer-motion";
import React from "react";
import {useStoreState} from "pullstate";
import {AppCall, getTranscripts} from "../../../stores";
import {TranscriptItem} from "./TranscriptItem";

interface Props extends React.ComponentProps<typeof motion.div> {
    //
}

export const CallTranscript: React.FC<Props> = ({...props}) => {

    const transcripts = useStoreState(AppCall, getTranscripts);

    return (
        <div className="my-4 w-full overflow-auto max-h-60">
            <div className="font-semibold">Transcription de l'appel</div>
            {transcripts.map((transcript, index) => (
                <TranscriptItem key={index} transcript={transcript}/>
            ))}
        </div>
    );
};
