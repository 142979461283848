import {Store} from "pullstate";
import {CallStore, ConversationTranscript, Product, TranscriptRequestData} from "../../interfaces";
import Vapi from "@vapi-ai/web";
import {products} from "../../data";
import {VAPI_PUBLIC_KEY} from "../../config";
import {Call} from "@vapi-ai/web/dist/api";
import {TranscriptRequest} from "../../assistant";

export const AppCall = new Store<CallStore>({
    buy: false,
    bounced: true,
    ip: "",
    inCall: false,
    isCalling: false,
    muted: false,
    volume: 0,
    showAddToCart: false,
    call: undefined,
    product: products[0],
    products: products,
    transcripts: [],
    vapi: new Vapi(VAPI_PUBLIC_KEY)
});

export const updateTranscript = (transcript: ConversationTranscript) => {
    AppCall.update((state) => {
        const last = state.transcripts.length ? state.transcripts[state.transcripts.length - 1] : null;
        const skip =
            last?.transcript === transcript.transcript &&
            last?.role === transcript.role;
        if (!skip) {
            const transcripts = [...state.transcripts, transcript];
            localStorage.setItem("transcripts", JSON.stringify(transcripts));
            return {
                ...state,
                transcripts,
            };
        }
    });

    //   AppCall.update((state) => ({
    //     transcripts: [...state.transcripts, transcript],
    //   }));
};

export const resetTranscript = () => {
    AppCall.update((state) => ({
        ...state,
        transcripts: [],
    }));
};

export const replaceProduct = (product: Product) => {
    AppCall.update((state) => ({
        ...state,
        product,
    }));
};

export const updateCall = (call: Call) => {
    AppCall.update((state) => ({
        ...state,
        call,
    }));
};

export const updateShowAddToCart = (show: boolean) => {
    AppCall.update((state) => ({
        ...state,
        showAddToCart: show,
    }));
}

export const updateBuy = (bought: boolean) => {
    AppCall.update((state) => ({
        ...state,
        buy: bought,
    }));
}
export const resetCall = () => {
    AppCall.update((state) => ({
        ...state,
        isCalling: false,
        inCall: false,
        muted: false,
        // showAddToCart: false,
        volume: false,
    }));
}

export const endCall = async (data: TranscriptRequestData) => {
    let last = localStorage.getItem("last_call") || null;
    const transcripts = JSON.parse(localStorage.getItem("transcripts") || JSON.stringify([]));

    if (data.call_id && last !== data.call_id) {
        if (transcripts.length > 0) {
            const request = {...data, transcript: transcripts};
            console.log("Request: ", request);
            try {
                localStorage.setItem("last_call", data.call_id);
                const res = await TranscriptRequest.create(request);
                last = localStorage.getItem("last_call");
                console.log("Latest: ", last);
                console.log("API Results: ", res);
                return res;
            } catch (e) {
                console.log(e);
            } finally {
                resetCall();
            }
        }
    } else {
        resetCall();
    }
}
