import axios, { AxiosRequestConfig } from "axios";
import { TRANSCRIPT_ENDPOINT } from "../config";
import { TranscriptRequestData } from "../interfaces";

export class MakeRequest {
    static get = (url: string, config?: AxiosRequestConfig) =>
        axios.get(url, config);
    static post = (url: string, data?: any, config?: AxiosRequestConfig) =>
        axios.post(url, data, config);
}

export class TranscriptRequest {
    static create = async (transcript: TranscriptRequestData) => {
        // console.log(transcript.product_id, transcript.category);u
        const response = await MakeRequest.post(TRANSCRIPT_ENDPOINT, transcript);
        return response.data;
    };
}

export class ClientRequest {
    static getClientIp = async () => {
        const response = await MakeRequest.get('https://api.ipify.org?format=json');
        console.log(response.data);
        return response.data.ip || "";
    };
}
