import {createSelector} from "reselect";
import {CallStore, TranscriptRequestData} from "../../interfaces";

const getState = (state: CallStore) => state;

export const getCall = createSelector(
    [getState],
    (state: CallStore) => state.call
);

export const getTranscripts = createSelector(
    [getState],
    (state: CallStore) => state.transcripts
);

export const getVapi = createSelector(
    [getState],
    (state: CallStore) => state.vapi
);

export const getProducts = createSelector(
    [getState],
    (state: CallStore) => state.products
);

export const getProduct = createSelector(
    [getState],
    (state: CallStore) => state.product
);

export const getCallInProgress = createSelector(
    [getState],
    (state: CallStore) => state.call !== null
);

export const getCallId = createSelector(
    [getState],
    (state: CallStore) => state.call?.id
);

export const getIp = createSelector(
    [getState],
    (state: CallStore) => state.ip,
);

export const getCallStates = createSelector(
    [getState],
    (state: CallStore) => ({
        isCalling: state.isCalling,
        inCall: state.inCall,
        muted: state.muted,
        showAddToCart: state.showAddToCart,
        callInProgress: state.isCalling || state.inCall,
    })
);

export const getCallDetails = createSelector(
    [getState],
    (state: CallStore) => state
);

export const getEndCallRequest = createSelector(
    [getCallDetails, getIp],
    (details: CallStore, ip: string): TranscriptRequestData => ({
        call_id: details.call?.id || "",
        product_id: details.product.id,
        category: details.product.category,
        transcript: details.transcripts,
        clicked_before_10_seconds: details.bounced,
        buy: details.buy,
        ip_address: ip,
    })
);

export const getCallVolume = createSelector(
    [getState],
    (state: CallStore) => state.volume,
);
